const global = {
  domain: 'hf.yheart.cn',
  base: {
    url: '//sell.hf.yheart.cn/api',
    api: '//sell.hf.yheart.cn/api'
  },
  file: {
    url: 'https://sell.lexiangspace.com/api'
  },
  fileTypeMimes: {
    'Attach': '*',
    'Image': 'image/gif,image/jpeg,image/bmp,image/png',
    'Pdf': 'application/pdf',
    'Excel': 'application/vnd.ms-excel',
    'ImgPdf': 'image/gif,image/jpeg,image/bmp,image/png,application/pdf'
  }
}

export default global
